import { useAsync } from 'react-use';

import { PureAPI } from '@src/swagger';

import { isEmptyString, isNotEmptyArray, isNotSet } from '@src/utils';

let cachedCities: Array<Option> = [];
let cachedDistricts: { city: string; options: Array<Option> } = {
  city: '',
  options: [],
};

const useAddressOption = (
  { city }: { city: undefined | string } = { city: undefined }
) => {
  const { value: cities = [] as Array<Option> } = useAsync(async () => {
    if (isNotEmptyArray(cachedCities)) return cachedCities;

    return await PureAPI.get<{ payload: Array<Option> }>('/api/cities').then(
      (res) => {
        const options = res.data.payload;
        cachedCities = options;
        return options;
      }
    );
  }, []);

  const { value: districts = [] } = useAsync(async () => {
    if (isNotSet(city) || isEmptyString(city)) return [];
    if (
      isNotEmptyArray(cachedDistricts.options) &&
      cachedDistricts.city === city
    )
      return cachedDistricts.options;

    const options = await PureAPI.get<{ payload: Array<Option> }>(
      `/api/districts/${city!}`
    ).then((res) =>
      [{ key: '', label: '不分區' } as Option].concat(res.data.payload)
    );

    cachedDistricts = {
      city: city!,
      options,
    };

    return options;
  }, [city]);

  return { cities, districts };
};

export default useAddressOption;
