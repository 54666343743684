import React from 'react';

import classNames from 'classnames';

import { faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';

import { pathname } from '@src/constant';

import { useApplicationContext } from '@src/context';

import Flexbox from '@src/component/common/Flexbox';
import Image from '@src/component/common/Image';

const ProfileAvatar: FC<{ className?: string }> = ({ className }) => {
  const { user } = useApplicationContext();

  return (
    <Link href={pathname.profile}>
      <Flexbox
        align={'center'}
        justify={'end'}
        className={classNames(className)}
      >
        <Image
          src={user?.avatarPhotoFile?.url ?? ''}
          alt={'personal image'}
          imagePlaceholder={
            <FontAwesomeIcon icon={faUserAlt} className={'-mb-2 w-6 h-6'} />
          }
          width={30}
          height={30}
          className={
            'w-[1.875rem] h-[1.875rem] text-grey-4 bg-grey-6 rounded-extreme'
          }
        />
      </Flexbox>
    </Link>
  );
};

export default ProfileAvatar;
