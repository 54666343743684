import React, {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import classNames from 'classnames';

import NextImage, { ImageProps } from 'next/image';

import { isEmptyString, isNotSet, isSet } from '@src/utils';

import ConditionalFragment from '@src/component/common/ConditionalFragment';
import Flexbox from '@src/component/common/Flexbox';

interface Props extends Omit<ImageProps, 'src' | 'alt'> {
  conditional?: boolean;
  imagePlaceholder?: React.ReactElement;
  useDefaultPlaceholder?: boolean;
  disableObjectFit?: boolean;
  disableObjectPosition?: boolean;
  src: ImageProps['src'] | undefined;
  alt: ImageProps['alt'] | undefined;
  imageClassName?: string;
}

const DefaultPlaceholder = () => <div className={'w-full h-full bg-grey-5'} />;

const Image = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      conditional = true,
      className,
      alt = '',
      imagePlaceholder,
      useDefaultPlaceholder,
      width,
      height,
      disableObjectFit,
      disableObjectPosition,
      src = '',
      children,
      imageClassName,
      ...props
    },
    ref
  ) => {
    const [isError, setIsError] = useState<boolean>(false);

    const shouldPlaceholderShow = useMemo(() => {
      return (
        isSet(imagePlaceholder) &&
        (isError || isNotSet(src) || isEmptyString(src))
      );
    }, [isError, imagePlaceholder, src]);

    const onLoad = useCallback(() => setIsError(false), []);
    const onError = useCallback(() => setIsError(true), []);

    return (
      <Flexbox
        ref={ref}
        conditional={conditional}
        justify='center'
        align='center'
        className={classNames(className, 'relative overflow-hidden')}
      >
        <ConditionalFragment condition={isSet(width) && isSet(height)}>
          <div
            style={{
              paddingTop: `${
                ((Number(height) ?? 1) / (Number(width) ?? 1)) * 100
              }%`,
            }}
          />
        </ConditionalFragment>
        <Flexbox
          justify={'center'}
          align={'center'}
          className={classNames('w-full h-full', {
            absolute: isSet(width) && isSet(height),
          })}
        >
          {shouldPlaceholderShow ? (
            imagePlaceholder
          ) : useDefaultPlaceholder && (isError || isEmptyString(src)) ? (
            <DefaultPlaceholder />
          ) : (
            <NextImage
              src={src}
              alt={alt}
              onLoad={onLoad}
              onError={onError}
              className={classNames(
                'w-full',
                'h-full',
                {
                  'object-cover': !disableObjectFit,
                  'object-center': !disableObjectPosition,
                },
                imageClassName
              )}
              width={props.fill ? undefined : width}
              height={props.fill ? undefined : height}
              {...props}
            />
          )}
        </Flexbox>
        {children}
      </Flexbox>
    );
  }
);

export default Image;
