import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import {
  Control,
  FieldPath,
  SubmitHandler,
  useController,
  useForm,
} from 'react-hook-form';
import { useAsync } from 'react-use';

import classNames from 'classnames';
import { endOfDay, startOfDay } from 'date-fns';
import { chunk } from 'lodash';
import qs from 'query-string';

import { faMapLocationDot } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouter } from 'next/router';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import { pathname } from '@src/constant';

import { PublicAPI } from '@src/swagger';

import {
  createTaiwanDate,
  getTaiwanTime,
  isNotEmptyString,
  isRequired,
  multipleValueRequired,
  storage,
  StorageProperties,
} from '@src/utils';

import useAddressOption from '@src/hooks/useAddressOption';

import { LogoIcon } from '@/public/assets/image/icon';
import { MbrSearchTwodayStoreWithSlotReq } from '@src/swagger/consumer.api';

import Button from '@src/component/common/Button';
import ConditionalFragment from '@src/component/common/ConditionalFragment';
import { RenderDisplayProps, Select } from '@src/component/common/Field';
import Flexbox from '@src/component/common/Flexbox';

import ProfileAvatar from '@src/component/layout/ProfileAvatar';

export interface SearchStoreWithSlotCriteria
  extends Omit<MbrSearchTwodayStoreWithSlotReq, 'time'> {
  timeFrom: string;
  timeUntil: string;
}

const Home = () => {
  const router = useRouter();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    watch,
  } = useForm<SearchStoreWithSlotCriteria>({
    mode: 'all',
    defaultValues: {
      addressCity: storage.getter(StorageProperties.LAST_SELECT_CITY) ?? '',
      addressDistrict:
        storage.getter(StorageProperties.LAST_SELECT_DISTRICT) ?? '',
    },
  });

  const city = watch('addressCity');

  const { cities, districts } = useAddressOption({ city });
  const { value: categories = [] } = useAsync(
    async () =>
      await PublicAPI.staticOptionApi
        .getTwodayStoreServiceCategory()
        .then((response) => response.data),
    []
  );

  const onSearch = useCallback<SubmitHandler<SearchStoreWithSlotCriteria>>(
    async (criteria) => {
      const today = getTaiwanTime();

      await router.push({
        pathname: pathname.result,
        query: qs.stringify(
          Object.assign(criteria, {
            timeFrom: createTaiwanDate(startOfDay(today)).toISOString(),
            timeUntil: createTaiwanDate(endOfDay(today)).toISOString(),
          })
        ),
      });
      storage.setter(StorageProperties.LAST_SELECT_CITY, criteria.addressCity);
      storage.setter(
        StorageProperties.LAST_SELECT_DISTRICT,
        criteria.addressDistrict
      );
    },
    []
  );

  return (
    <Flexbox
      align={'center'}
      direction={'column'}
      className={classNames(
        'p-10 pt-[6.5rem]',
        'w-full h-full',
        'bg-pink-bg-light'
      )}
    >
      <ProfileAvatar className={'absolute top-4 right-6'} />
      <LogoIcon className={'mb-4 w-[11.25rem] text-pink'} />
      <h1 className={'mb-8 text-3xl text-center font-bold'}>
        美麗專屬．晚鳥預約
      </h1>
      <Flexbox
        align={'center'}
        className={classNames(
          'mb-4',
          'px-5',
          'w-full',
          'border border-solid border-pink',
          'rounded-xl'
        )}
      >
        <FontAwesomeIcon
          className={'mr-4 w-6 h-6 text-pink text-[22px]'}
          icon={faMapLocationDot}
        />
        <div className={'flex-1 overflow-hidden'}>
          <Select
            chevronClassName={'text-pink'}
            options={cities}
            control={control}
            name={'addressCity'}
            disableBolder
            disablePadding
            placeholder={'請選擇城市'}
            className={'py-3'}
            rules={{
              validate: isRequired,
            }}
            renderDisplay={({ displayValue, hasSelected }) => (
              <RenderDisplay
                displayValue={displayValue}
                hasSelected={hasSelected}
              />
            )}
          />
        </div>
      </Flexbox>
      <Flexbox
        align={'center'}
        className={classNames(
          'mb-8',
          'px-5',
          'w-full',
          'border border-solid border-pink',
          'rounded-xl'
        )}
      >
        <FontAwesomeIcon
          className={'mr-4 w-6 h-6 text-pink text-[22px]'}
          icon={faMapLocationDot}
        />
        <div className={'flex-1 overflow-hidden'}>
          <Select
            chevronClassName={'text-pink'}
            options={districts}
            control={control}
            name={'addressDistrict'}
            disableBolder
            disablePadding
            placeholder={'請選擇地區'}
            className={'py-3'}
            renderDisplay={({ displayValue, hasSelected }) => (
              <RenderDisplay
                displayValue={displayValue}
                hasSelected={hasSelected}
              />
            )}
          />
        </div>
      </Flexbox>
      <div className={'mb-10 w-full'}>
        <Flexbox
          align={'center'}
          justify={'between'}
          className={'mb-4 text-sm'}
        >
          <p className={'text-grey-2'}>請選擇分類</p>
          <p className={'text-grey-3'}>複選</p>
        </Flexbox>
        <CategorySelectField
          control={control}
          name={'serviceCategories'}
          options={categories}
          rules={{ validate: multipleValueRequired }}
        />
      </div>
      <Button
        disabled={!isValid || isSubmitting}
        onClick={handleSubmit(onSearch)}
        buttonType={'pink'}
        className={'w-full mb-14'}
      >
        <FontAwesomeIcon icon={faSearch} className={'mr-2 w-4 h-4'} />
        搜尋
      </Button>
      <footer className={'mt-auto text-grey-2 text-2sm'}>
        <p className={'mb-1 text-center'}>TWODAY © 2023. All rights reserve</p>
        <Flexbox
          as={'p'}
          align={'center'}
          justify={'center'}
          className={'text-center'}
        >
          <a
            href={'https://docs.twoday.beauty/term'}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            使用者條款
          </a>
          <span className={'inline-block mx-2 w-px h-4 bg-grey-2'} />
          <a
            href={'https://docs.twoday.beauty/privacy'}
            target={'_blank'}
            rel={'noreferrer noopener'}
          >
            隱私權政策
          </a>
        </Flexbox>
      </footer>
    </Flexbox>
  );
};

// noinspection JSUnusedGlobalSymbols
export default Home;

const RenderDisplay: React.FC<
  RenderDisplayProps & {
    label?: string;
  }
> = ({ label = '', displayValue }) => {
  return (
    <div className={'mr-2 flex-1 overflow-hidden'}>
      <ConditionalFragment condition={isNotEmptyString(label)}>
        <h5 className={'mb-0.5 text-sm font-bold'}>{label}</h5>
      </ConditionalFragment>
      <p className={'w-full text-base text-grey-2 truncate'}>{displayValue}</p>
    </div>
  );
};

interface CategorySelectFieldComponent extends CustomizeFunctionComponent {
  <FormValues, Name extends FieldPath<FormValues>>(
    props: PropsWithChildren<{
      options: Array<Option>;
      control: Control<FormValues>;
      name: Name;
      rules?: Omit<
        RegisterOptions<FormValues, Name>,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
      >;
    }>,
    context?: any
  ): React.ReactElement | null;
}

const CategorySelectField: CategorySelectFieldComponent = ({
  control,
  name,
  options,
  rules,
}) => {
  const {
    field: { value = [], onChange },
  } = useController({ control, name, rules });

  const chunkedOptions = useMemo(() => chunk(options, 3), [options]);

  const onCategoryClick = useCallback(
    (incomingValue: string, existingValues: Array<string>) => {
      if (existingValues.includes(incomingValue)) {
        onChange(existingValues.filter((value) => value !== incomingValue));
      } else {
        onChange(existingValues.concat(incomingValue));
      }
    },
    []
  );

  return (
    <div className={'w-full'}>
      {chunkedOptions.map((row, index) => (
        <Flexbox key={`${index}`} align={'center'} className={'mb-4 last:mb-0'}>
          {row.map(({ key, label }) => {
            const isSelected = (value as Array<string>).includes(key as string);

            return (
              <button
                onClick={() =>
                  onCategoryClick(key as string, value as Array<string>)
                }
                key={`${key}`}
                className={classNames(
                  'flex-1',
                  'mr-4 last:mr-0',
                  'py-2',
                  'text-center',
                  'rounded-extreme',
                  {
                    'bg-white text-grey-2': !isSelected,
                    'bg-pink text-white font-bold': isSelected,
                  }
                )}
              >
                {label}
              </button>
            );
          })}
        </Flexbox>
      ))}
    </div>
  );
};
